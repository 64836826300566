<template>
  <div class="noscrollmain">
    <common-top
      title="文章已读/未读"
      :needEmit="true"
      @back="back"
      @search="showModal = true"
      :isShowSearch="isUserPm"
    ></common-top>

    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-if="isUserPm">
      <!-- 选择部门 -->
      <dept-choose-com
        :isShowTime="false"
        :showModal="showModal"
        @chooseDept="chooseDept"
        @cancel="showModal = false"
        :isShowDept="true"
      ></dept-choose-com>

      <div style="padding: 10px; font-size: 14px">
        <div style="text-align: left; color: #aaa">
          <span v-if="contentInfo.title">{{ contentInfo.title }}</span>
        </div>
      </div>

      <!-- 统计数据 -->
      <div v-if="!showContentList">
        <div
          style="display: flex; justify-content: space-between; padding: 0 10px"
        >
          <div
            style="
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: right;
            "
            @click="showContentList = true"
          >
            <svg
              style="display: block"
              t="1648189599515"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="11006"
              width="16"
              height="16"
            >
              <path
                d="M757.8112 950.3232H271.5648c-107.264 0-194.2016-86.9376-194.2016-194.2016V269.824c0-107.264 86.9376-194.2016 194.2016-194.2016h486.2464c107.264 0 194.2016 86.9376 194.2016 194.2016v486.2464c0 107.264-86.9376 194.2528-194.2016 194.2528z"
                fill="#F78748"
                p-id="11007"
              ></path>
              <path
                d="M790.2208 514.1504c-14.1312 0-25.6 11.4688-25.6 25.6v172.5952c0 46.1824-37.5808 83.7632-83.7632 83.7632h-322.048c-46.1824 0-83.7632-37.5808-83.7632-83.7632V382.3616c0-46.1824 37.5808-83.7632 83.7632-83.7632h7.8848c10.24 45.312 50.7392 79.2576 99.072 79.2576h108.0832c48.3328 0 88.832-33.9456 99.072-79.2576h7.8848c46.1824 0 83.7632 37.5808 83.7632 83.7632 0 14.1312 11.4688 25.6 25.6 25.6s25.6-11.4688 25.6-25.6c0-74.3936-60.5184-134.9632-134.9632-134.9632h-9.5744c-12.4416-42.0352-51.4048-72.8064-97.4336-72.8064h-108.032c-46.0288 0-84.9408 30.7712-97.4336 72.8064h-9.5744c-74.3936 0-134.9632 60.5184-134.9632 134.9632v330.0352c0 74.3936 60.5184 134.9632 134.9632 134.9632h322.048c74.3936 0 134.9632-60.5184 134.9632-134.9632v-172.5952a25.5488 25.5488 0 0 0-25.5488-25.6512zM465.7664 225.792h108.0832c27.8016 0 50.432 22.6304 50.432 50.432s-22.6304 50.432-50.432 50.432H465.7664c-27.8016 0-50.432-22.6304-50.432-50.432s22.6304-50.432 50.432-50.432z"
                fill="#F7F8F8"
                p-id="11008"
              ></path>
              <path
                d="M615.2704 468.5824H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6zM615.2704 613.2224H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6z"
                fill="#F7F8F8"
                p-id="11009"
              ></path>
            </svg>
            <span style="font-weight: bold">选择文章</span>
          </div>
          <div
            class="listRankTitle"
            style="justify-content: flex-end; margin: 0"
          >
            <div
              v-for="(item, key) in ['已读', '未读']"
              :key="key"
              @click="chooseRankType(item)"
              :class="item == curRankCycle ? 'banganStyAcvive' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <list-com
          v-if="curRankCycle == '已读'"
          :columns="columns"
          :descWidth="true"
          :showList="readList"
          :showLoad="showLoad"
          :multiTitleArr="multiTitleArr"
          :singelTitle="true"
          :title="title"
          :showLoadMore="false"
        ></list-com>

        <list-com
          v-if="curRankCycle == '未读'"
          :columns="columnsUnread"
          :descWidth="false"
          :showList="unReadList"
          :showLoad="showLoad"
          :singelTitle="true"
          :title="title"
          :showLoadMore="false"
        ></list-com>

        <load-more
          @refresh="onRefresh"
          :load-status="isLoadMore"
          :isShow="loadMoreShow"
        ></load-more>
      </div>

      <!-- 搜索文章列表 -->
      <search-content-list
        :showLoad="showContentList"
        @searchBack="searchContentBack"
        v-if="showContentList"
        @chooseItem="chooseContentItem"
      ></search-content-list>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
import _ from "lodash";
// @ is an alias to /src
import { statsPost, getUserPm } from "../../api/httpApi";
import pieChart from "../../components/pieChart.vue";
import commonTop from "../../components/commonTop.vue";
import searchContentList from "../../components/searchContentList.vue";
import contentData from "../../components/contentData.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import noPassCom from "../../components/noPassCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import listCom from "../../components/listCom.vue";
import loadMore from "../../components/loadMoreData.vue";
import diaLogliu from "../../components/dialogLiu.vue";

export default {
  name: "contentStats",
  components: {
    pieChart,
    commonTop,
    searchContentList,
    loadingCmp,
    deptChooseCom,
    contentData,
    noPassCom,
    listCom,
    loadMore,
    diaLogliu,
  },
  data() {
    return {
      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      loadMoreShow: false,
      isLoadMore: true,

      isUserPm: true, // 是否有权限
      showModal: false, // 是否显示单位
      showContentList: true, // 是否显示 文章列表
      showLoad: false, // 数据加载
      isShowSearch: true,
      deptType: "单位",
      searchData: {},
      _cid: "",
      contentInfo: {},

      deptIdArr: [], // 选择部门

      title: "name",
      multiTitleArr: [
        // {
        //   name: "单位",
        //   value: "dept2Name",
        // },
        // {
        //   name: "车间",
        //   value: "dept3Name",
        // },
      ],

      columns: [
        // {
        //   name: "年龄",
        //   value: "idCardRange",
        // },
        {
          name: "阅读量",
          value: "count",
        },

        {
          name: "点赞量",
          value: "like",
        },
        {
          name: "分享量",
          value: "share",
        },
        {
          name: "评论量",
          value: "comment",
        },

        {
          name: "优质评论",
          value: "commentView",
        },
      ],
      readList: [],
      curRankCycle: "已读",

      // 未读列表
      unReadList: [],

      multiTitleUnReadArr: [
        {
          name: "姓名",
          value: "name",
        },
        // {
        //   name: "单位",
        //   value: "dept2Name",
        // },
        // {
        //   name: "班组",
        //   value: "dept31Name",
        // },
      ],
      columnsUnread: [
        {
          name: "政治面貌",
          value: "politicalStatus",
        },
        // {
        //   name: "单位",
        //   value: "dept11Name",
        // },
        {
          name: "单位",
          value: "dept2Name",
        },
        {
          name: "部门",
          value: "dept3Name",
        },
        {
          name: "科室",
          value: "dept4Name",
        },
      ],
    };
  },
  methods: {
    showDiaLog(val) {
      this.dailogshow = true;
      this.dailogoptions.content = val;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },
    // 更换已读未读
    chooseRankType(type) {
      if (this.showLoad) {
        this.showDiaLog("正在查询，请稍后");
        return;
      }
      this.curRankCycle = type;
      this.showLoad = false;

      this.readList = [];
      this.unReadList = [];

      this.getUserList();
    },

    searchContentBack() {
      // 若已选择文章，在 ”选择文章“ 中重新选择文章
      if (this.readList.length > 0) {
        this.showContentList = false;
        return;
      }
      if (this.contentInfo._cid) {
        this.showContentList = true;
      } else {
        this.$router.back(-1);
      }
    },
    back() {
      this.$router.push({ path: "/statsmain" });
    },
    async chooseDept(emitData) {
      this.deptIdArr = emitData.deptIdArr;
      this.showModal = false;
      this.getUserList();
    },

    async getUserList() {
      try {
        this.showLoad = true;
        this.showContentList = false; // 显示
        let skip = this.readList.length;
        if (this.curRankCycle == "未读") {
          skip = this.unReadList.length;
        }
        const _query = {
          _cid: this._cid,
          limit: 20,
          skip,
          deptIdArr: this.deptIdArr,
          bUnRead: this.curRankCycle == "已读" ? false : true,
        };

        const ret = await statsPost("/content/userArr", _query);
        if (ret.errMsg) {
          this.$Message.info(ret.errMsg);
          this.showLoad = false;
          return;
        }

        if (ret.ret.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
          this.showLoad = false;

          return;
        }
        if (this.curRankCycle == "已读") {
          this.readList = _.concat(this.readList, ret.ret);
        } else {
          this.unReadList = _.concat(this.readList, ret.ret);
        }

        this.isLoadMore = true;
        this.loadMoreShow = true;
        this.showLoad = false;
      } catch (error) {
        console.log("----", error);
      }
    },

    chooseContentItem(data) {
      if (data) {
        this.contentInfo = data;
        this.showContentList = false;

        this.readList = [];
        this.unReadList = [];

        this._cid = data["_cid"];
        this.getUserList();
      } else {
        this.showDiaLog("请选择文章");
      }
    },

    async onRefresh() {
      try {
        if (this.showLoad) {
          return;
        }

        this.showLoad = true;
        let skip = this.readList.length;
        if (this.curRankCycle == "未读") {
          skip = this.unReadList.length;
        }
        const _query = {
          _cid: this._cid,
          limit: 20,
          skip,
          deptIdArr: this.deptIdArr,
          bUnRead: this.curRankCycle == "已读" ? false : true,
        };

        const ret = await statsPost("/content/userArr", _query);
        if (ret.errMsg) {
          this.$Message.info(ret.errMsg);
          this.isLoadMore = true;
          this.loadMoreShow = true;
          this.showLoad = false;
          return;
        }

        if (ret.ret.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
          this.showLoad = false;
          return;
        }

        // 显示加载
        if (this.curRankCycle == "已读") {
          this.readList = _.concat(this.readList, ret.ret);
        } else {
          this.unReadList = _.concat(this.unReadList, ret.ret);
        }
        this.isLoadMore = true;
        this.loadMoreShow = true;
        this.showLoad = false;
      } catch (error) {
        console.log("onRefresh---", error);
      }
    },
  },
  async created() {
    const userPm = getUserPm();
    if (userPm) {
      this.level = userPm.datalevel;

      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));
      console.log(123)
      console.log(this.deptIdArr)
      if (userPm.pmValue && userPm.statsPmStatus) {
        this.isShowSearch = true;
      }

      // this.getUserList();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped></style>
